<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('物流公司信息.公司名称')" prop="name">
                <a-input v-model="queryParam.name" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('物流公司信息.公司名称')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('物流公司信息.公司编码')" prop="code">
                <a-input v-model="queryParam.code" @pressEnter="handleQuery" :placeholder="$t('通用.输入.请输入')+$t('物流公司信息.公司编码')" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('物流公司信息.公司类型')" prop="type">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('物流公司信息.公司类型')" style="width: 100%" v-model="queryParam.type" allowClear>
                  <a-select-option value="国内运输商">国内运输商</a-select-option>
                  <a-select-option value="国际邮政">国际邮政</a-select-option>
                  <a-select-option value="国际运输商">国际运输商</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item :label="$t('物流公司信息.是否选用')" prop="isSelect">
                <a-select :placeholder="$t('通用.输入.请选择')+$t('物流公司信息.是否选用')" style="width: 100%" v-model="queryParam.isSelect" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.TFEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{$t('通用.按钮.查询')}}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />{{$t('通用.按钮.重置')}}</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
        <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
          <img alt="example" style="width: 100%" :src="previewUrl" />
        </a-modal>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['order:logistics:edit']">
          <a-icon type="edit" />{{$t('通用.按钮.修改')}}
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:logistics:export']">
          <a-icon type="download" />{{$t('通用.按钮.导出')}}
        </a-button>
        <a-button type="primary" :disabled="multiple"  @click="handleSelectExport" v-hasPermi="['order:logistics:export']">
          <a-icon type="download" />{{$t('通用.按钮.选中导出')}}
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        @change="handleSortChange"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="logoUrl" slot-scope="text, record">
          <div>
            <img v-if="record.logoUrl" :src="record.logoUrl"  style="width:60px;height:62px;" @click="handleRecordPreview(record.logoUrl)" alt="none"/>
          </div>
        </template>
        <span slot="isSelect" slot-scope="text, record">
          <a-switch checked-children="开" un-checked-children="关" :checked="record.isSelect" @change="e=>changeSelect(e,record)"/>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['order:logistics:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['order:logistics:edit']">
             <a-icon type="edit" />{{$t('通用.按钮.修改')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {pageLogistics, listLogistics, delLogistics, updateLogistics} from '@/api/order/logistics'
import CustomDictTag from "@/components/DictCustomTag";
import CreateForm from "./modules/CreateForm";
import {mapGetters} from 'vuex'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Logistics',
  components: {
    CustomDictTag,
    CreateForm,
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      filteredInfo: {},
      sortedInfo : {},
      selectedRowKeys: [],
      selectedRows: [],
      //预览封面
      previewImageVisible: false,
      previewUrl: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: undefined,
        code: undefined,
        type: undefined,
        isSelect: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: this.$t('物流公司信息.编号'),
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('物流公司信息.公司名称'),
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('物流公司信息.公司编码'),
          dataIndex: 'code',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('物流公司信息.公司类型'),
          dataIndex: 'type',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('物流公司信息.公司logo'),
          dataIndex: 'logoUrl',
          scopedSlots: {customRender: 'logoUrl'},
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('物流公司信息.是否选用'),
          dataIndex: 'isSelect',
          scopedSlots: { customRender: 'isSelect' },
          align: 'center'
        },
        {
          title: this.$t('物流公司信息.排序'),
          dataIndex: 'sort',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('物流公司信息.备注'),
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          width: '12%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    /** 查询物流公司信息列表 */
    getList () {
      this.loading = true
     pageLogistics(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        name: undefined,
        code: undefined,
        type: undefined,
        logoUrl: undefined,
        isSelect: undefined,
        sort: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    handleSortChange(pagination, filters, sorter) {
      this.filteredInfo = filters
      this.sortedInfo = sorter
      if (sorter.order) {
        /** 排序字段 sortField **/
        /** 排序方式  sortOrder**/
        this.queryParam.sortField = sorter.columnKey
        this.queryParam.sortOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
        this.getList()
      } else {
        this.queryParam.sortField = '';
        this.queryParam.sortOrder = ''
        this.getList()
      }
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    changeSelect(value,record) {
      console.log('value',value)
      console.log('recor',record)
      let data = {
        id: record.id,
        isSelect: value
      };
      record.isSelect = value;
      updateLogistics(data).then(response => {
        this.getList()
      }).finally(() => {
      })

    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出当前条件下所有数据而非选中数据'),
        onOk () {
          that.download('order/logistics/export',
            that.queryParam
          , `物流公司信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleSelectExport () {
      var that = this
      let queryParam = {"idList": this.ids}
      this.$confirm({
        title: this.$t('通用.文本.是否确认导出'),
        content: this.$t('通用.文本.此操作将导出选中数据'),
        onOk () {
          that.download('order/logistics/export', queryParam, `物流公司信息_${new Date().getTime()}.xlsx`)
          that.ids = []
          that.multiple=true
          that.selectedRowKeys=[]
        },
        onCancel () {
          that.ids = []
          that.multiple=true
          that.selectedRowKeys=[]
        }
      })
    }
  }
}
</script>
